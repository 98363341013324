import BaseBean from "@/utils/BaseBean";

export interface IPersonListDataObj {
    utilInst:PersonListUtil
    refMap:Map<string,any>
    pageListRef:any
    corpTreeData: Array<any>
    deptTreeData: Array<any>
    pageList:any
    importGuidParams:any
}
export default class PersonListUtil extends BaseBean{
    public dataObj:IPersonListDataObj

    constructor(proxy:any,dataObj:IPersonListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    public async requestDeptData(corpId:string,utilInst:PersonListUtil):Promise<any>{
        utilInst.dataObj.deptTreeData = await utilInst.utils.Api.deptTreeData({
            corpId: corpId
        });
    }
}