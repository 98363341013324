import { ref,computed,getCurrentInstance,reactive,toRefs,onBeforeMount,onMounted,defineComponent} from 'vue';
import PersonCardUtil,{IPersonCardDataObj} from "@/views/sysviews/organization/person/personCardUtil";

export default defineComponent({
    name: "personCard",
    title: "人员管理",
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IPersonCardDataObj=reactive<IPersonCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            corpTreeData: [],
            deptTreeData: [],
            compParams: {
                modelPath: utils.Api.buildUrl("/person")
            },
            form: { },
            rules: {
                code:[utils.UtilPub.commonValidRule(proxy.$t('person.form.code_rule'))],
                name: [utils.UtilPub.commonValidRule(proxy.$t('person.form.name_rule'))],
                corpId: [utils.UtilPub.commonValidRule(proxy.$t('person.form.corpId_placeholder'))],
                // officeTel: [{min: 0,max: 99999999999,message: "长度在 0 到 11 个数字",trigger: "blur"}],
                // homeTel: [{min: 0,max: 99999999999,message: "长度在 0 到 11 个数字",trigger: "blur"}],
                // mobile: [{min: 0,max: 99999999999,message: "长度在 0 到 11 个数字",trigger: "blur"}]
            },
            otherParams:{
                disableField:false
            }
        })
        onBeforeMount(()=>{dataObj.utilInst=new PersonCardUtil(proxy,dataObj)})
        onMounted(()=>{})
        const beforeOpen=async(res:any, addOrLoad:string)=>{
            dataObj.corpTreeData = await utils.Api.corpTreeData();
            await dataObj.utilInst.requestDeptData(res.data.corpId);
            dataObj.otherParams.disableField=res.disableField;
            if(res.disabled!=undefined)dataObj.disabled=res.disabled;
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        const startHandler=()=>{
            dataObj.utilInst.changeStatus({id: dataObj.form.id,type:'start',operateType:'changeStatus'});
        }
        const stopHandler=()=>{
            dataObj.utilInst.changeStatus({id: dataObj.form.id,type:'stop',operateType:'changeStatus'});
        }
        return{
            ...toRefs(dataObj),beforeOpen,beforeSaveHandler,startHandler,stopHandler
        }
    },

    components: {}
});