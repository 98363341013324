export default {
    zh:{
        personCard:'人员管理',
        person:{
            downLoadFileName:'人员导入模版.xlsx',
            queryParam:{
                code:'编码',
                code_placeholder:'请输入编码模糊匹配查询...',
                name:'名称',
                name_placeholder:'请输入名称模糊匹配查询...',
                corpId:'机构',
                deptId:'部门',
            },
            columns:{
                F_CODE:'编码',
                F_NAME:'名称',
                F_CORP_NAME:'机构',
                F_DEPT_NAME:'部门',
                F_SEX:'性别',
                F_MOBILE:'联系电话',
            },
            form:{
                code:'编码',
                code_placeholder:'请输入编码',
                code_rule:'请输入编码',
                name:'名称',
                name_placeholder:'请输入名称',
                name_rule:'请输入名称',
                corpId:'机构',
                corpId_placeholder:'请选择机构',
                corpId_rule:'请选择机构',
                deptId:'部门',
                title:'称谓',
                title_placeholder:'请输入称谓',
                position:'职位',
                position_placeholder:'请输入职位',
                officeTel:'办公电话',
                officeTel_placeholder:'请输入办公电话',
                homeTel:'住宅电话',
                homeTel_placeholder:'请输入住宅电话',
                mobile:'手机',
                mobile_placeholder:'请输入手机',
                email:'电子邮箱',
                email_placeholder:'请输入电子邮箱',
                sex:'性别',
                birthdate:'出生日期',
                birthdate_placeholder:'请选择出生日期',
                sfz:'身份证',
                sfz_placeholder:'请输入身份证',
                desc:'说明',
                desc_placeholder:'请输入说明',
            }
        }
    },
    en:{
        personCard:'person management',
        person:{
            downLoadFileName:'personImportTemplate.xlsx',
            queryParam:{
                code:'code',
                code_placeholder:'Please enter a code fuzzy matching query...',
                name:'name',
                name_placeholder:'Please enter a name fuzzy matching query...',
                corpId:'corp',
                deptId:'dept',
            },
            columns:{
                F_CODE:'code',
                F_NAME:'name',
                F_CORP_NAME:'corp',
                F_DEPT_NAME:'dept',
                F_SEX:'sex',
                F_MOBILE:'mobile',
            },
            form:{
                code:'code',
                code_placeholder:'Please enter a code',
                code_rule:'Please enter a code',
                name:'name',
                name_placeholder:'Please enter a name',
                name_rule:'Please enter a name',
                corpId:'corp',
                corpId_placeholder:'Please select corp',
                corpId_rule:'Please select corp',
                deptId:'department',
                title:'title',
                title_placeholder:'Please enter a title',
                position:'position',
                position_placeholder:'Please enter a position',
                officeTel:'officeTel',
                officeTel_placeholder:'Please enter an office phone number',
                homeTel:'Home Phone',
                homeTel_placeholder:'Please enter your home phone number',
                mobile:'mobile',
                mobile_placeholder:'Please enter your mobile number',
                email:'email',
                email_placeholder:'Please enter an email address',
                sex:'sex',
                birthdate:'birthdate',
                birthdate_placeholder:'Please select a date of birth',
                sfz:'ID card',
                sfz_placeholder:'Please enter your ID card',
                desc:'explain',
                desc_placeholder:'Please enter explain',
            }
        }
    }
}